import './App.scss';
import { VerticalNav } from './layout/navigations/verticalNav/VerticalNav';
import { Navigation } from './layout/navigations/Navigation';
import { TopNav } from './layout/navigations/topNav/TopNav';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getUser } from './services/User';
function App() {
  const location = useLocation()
  const navigate = useNavigate()
  const stores = useSelector((state: any) => state)
  const allowedRoutes = ["/login"]
  useEffect(() => {
    (async () => {
      if (!allowedRoutes.includes(location.pathname)) {
        if (!isTokenValid()) {
          navigate("/login")
          return
        }
        const userData = await getUser(stores.user._id, stores.auth.token)
        if (!userData || !userData.data || userData.data.role !== 1 || !isTokenValid()) {
          navigate("/login")
          return
        }
      }
    })();
    return () => {
      // this now gets called when the component unmounts
    };
  }, [location]);
  /**
   * 
   * @returns {boolean} to determine if the token is valid or not
   */
  const isTokenValid = () => {
    return stores?.auth.token && stores?.auth.expiresDate > Date.now()
  }

  return (
    <div className="appCtn">
      {!allowedRoutes.includes(location.pathname) ?
        <header className="App-header">
          <VerticalNav></VerticalNav>
        </header> : null}
      <div className="ctnBody">
        {!allowedRoutes.includes(location.pathname) ? <TopNav></TopNav> : null}
        <Navigation></Navigation>
      </div>

    </div>
  );
}

export default App;
