import "./Announcements.scss";
import { useEffect, useState, useRef } from "react";
import { AnnouncementsList } from "../../components/announcements/list/List";
import { Editor } from "@tinymce/tinymce-react";
import { store } from "../../stores/store";
import { DateTime } from "luxon";
import { createNotification } from "../../services/Notification";
import { useSelector } from "react-redux";
import { toast, Toaster } from "react-hot-toast";
export function Announcements() {
    let form = {
        title: "",
        description: "",
    }
    //Auth token
    const { token } = useSelector((state: any) => state.auth);
    const notify = (msg: string) => toast.success(msg);
    const sendAnnouncement = async () => {
		if (form.title.length < 3 || form.description.length < 3) return;
		const notification = {
			title: form.title,
			description: form.description,
			from: store.getState().user._id,
			creationDate: DateTime.now().toMillis(),
			type: 1,
			seenBy: [],
		};
		const announcementSent = await createNotification(token, notification);
		if (announcementSent.data) {
            notify("Annonce créée avec succès")
		}
	};
	return (
		<section className="ctnAnnouncementsBody">
            <Toaster />
			<h1>Annonces</h1>
			<div className="ctnAnnouncementsComponent scroll">
				<AnnouncementsList />
			</div>
			<div className="ctnCreateAnnouncements">
				<h2>Créer une annonce</h2>
				<form>
					<input onChange={(e) => {
                        form.title = e.target.value;
                    }} className="customInput" type="text" placeholder="Titre de l'annonce" />
					<Editor
						apiKey="hbw7as726a9y6se79pgk83ywn1uh9h5arfsnw510nwxw7xl4"
						initialValue="<p>Indiquer le contenu de l'annonce</p>"
						onEditorChange={(newValue, editor) => {
							form.description = editor.getContent({ format: "html" });
						}}
						init={{
							height: "30vh",
							width: "100%",
							menubar: true,
							plugins: ["advlist", "autolink", "lists", "link", "image", "charmap", "preview", "anchor", "searchreplace", "visualblocks", "code", "fullscreen", "insertdatetime", "media", "table", "code", "help", "wordcount"],
							toolbar: "undo redo | blocks | " + "bold italic forecolor | alignleft aligncenter " + "alignright alignjustify | bullist numlist outdent indent | " + "removeformat | help",
							content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
						}}
					/>
                    <button onClick={() => {
                        sendAnnouncement();
                     }} type="button" className="sendAnnouncementBtn">Créer l'annonce</button>
				</form>
			</div>
		</section>
	);
}
