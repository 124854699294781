import { CardsFactory } from "../../factories/cards/CardsFactory";
import "./Cards.scss";
import enFlag from "../../assets/img/flags/en.png";
import frFlag from "../../assets/img/flags/fr.png";
import { getCardType } from "../../helpers/cards/getCardType";
import addBtn from "../../assets/img/btn/add.png";
import { useEffect, useState } from "react";
import onChange from "../../helpers/cards/onChange";
import onAttrAdd from "../../helpers/cards/onAttrAdd";
export function Cards({ card, isEditMode }: { card: any, isEditMode: boolean }) {
    const [liveCard, setLiveCard] = useState<any>({ ...card });
    let Card = new CardsFactory(card, getCardType(card));
    useEffect(() => {
        Card = new CardsFactory(liveCard, getCardType(liveCard));
    }, [liveCard])

    const update = (e: React.FocusEvent<HTMLParagraphElement, Element>, type: number, mainIndex: number, subIndex: number | null = null, lang?: number) => {
        const newCard = onChange(e, liveCard, type, mainIndex, subIndex, lang);
        setLiveCard(newCard);
    }

    const addAttr = (e: React.MouseEvent<HTMLImageElement, MouseEvent>, type: number, lang?: number, index?: number) => {
        const newCard = onAttrAdd(e, liveCard, type, lang, index);

        setLiveCard(newCard);
    }

    return (
        <div className="ctnCard">
            <div className="ctnContent">
                <h2>{Card.title}</h2>
                <div className="ctnMeanings">
                    <div>
                        <img src={enFlag} alt="Drapeau anglais" />
                    </div>
                    <div className={getCardType(card) === 0 ? "meanings scroll rowKanji" : "meanings scroll"}>
                        {getCardType(card) === 1 ?
                            <>
                                {Card.meanings.en.map((m: string[], mainIndex: number) => <div className="ctgGroupMeanings">{m.map((v: string, subIndex: number) => <p onBlur={(e) => update(e, 0, mainIndex, subIndex, 0)} contentEditable={isEditMode} suppressContentEditableWarning>{v}</p>)} <img style={!isEditMode ? { display: "none" } : {}} onClick={(e) => addAttr(e, 0, 0, mainIndex)} className="addBtn" src={addBtn} alt="Add btn" /> </div>)}
                                <img style={!isEditMode ? { display: "none" } : {}} onClick={(e) => addAttr(e, 0, 0)} className="addBtn" src={addBtn} alt="Add btn" />
                            </> : null}


                        {getCardType(card) === 0 ?
                            <>
                                {Card.meanings.en.map((m: string[], mainIndex: number) => <p onBlur={(e) => update(e, 0, mainIndex, undefined, 0)} contentEditable={isEditMode} suppressContentEditableWarning>{m}</p>)}
                                <img style={!isEditMode ? { display: "none" } : {}} onClick={(e) => addAttr(e, 0, 0)} className="addBtn" src={addBtn} alt="Add btn" />
                            </> : null}
                    </div>
                </div>
                <div className="ctnMeanings">
                    <div>
                        <img src={frFlag} alt="Drapeau français" />
                    </div>
                    <div className={getCardType(card) === 0 ? "meanings scroll rowKanji" : "meanings scroll"}>
                        {getCardType(card) === 1 ?
                            <>
                                {Card.meanings.fr.map((m: string[], mainIndex: number) => <div className="ctgGroupMeanings">{m.map((v: string, subIndex: number) => <p onBlur={(e) => update(e, 0, mainIndex, subIndex, 1)} contentEditable={isEditMode} suppressContentEditableWarning>{v}</p>)} <img style={!isEditMode ? { display: "none" } : {}} onClick={(e) => addAttr(e, 0, 1, mainIndex)} className="addBtn" src={addBtn} alt="Add btn" /> </div>)}
                                <img style={!isEditMode ? { display: "none" } : {}} onClick={(e) => addAttr(e, 0, 1)} className="addBtn" src={addBtn} alt="Add btn" />
                            </>
                            : null}
                        {getCardType(card) === 0 ?
                            <>
                                {Card.meanings.fr.map((m: string[], mainIndex: number) => <p onBlur={(e) => update(e, 0, mainIndex, undefined, 1)} contentEditable={isEditMode} suppressContentEditableWarning>{m}</p>)}
                                <img style={!isEditMode ? { display: "none" } : {}} onClick={(e) => addAttr(e, 0, 1)} className="addBtn" src={addBtn} alt="Add btn" />
                            </> : null}

                    </div>
                </div>
            </div>
            <h3>Lectures</h3>
            <div className="ctnContent">
                {Object.keys(Card.readings).map((r: string, index: number) => {
                    return (
                        <div key={"reading" + index} className="ctnMeanings scroll">
                            <h4>{r}</h4>
                            {Card.readings[r].map((r: string, i: number) => <p onBlur={(e) => update(e, 1 + index, i)} contentEditable={isEditMode} suppressContentEditableWarning key={"meanThree" + i} className="meaning">{r}</p>)}
                            <img style={!isEditMode ? { display: "none" } : {}} onClick={(e) => addAttr(e, 1 + index)} className="addBtn" src={addBtn} alt="Add btn" />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}