import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Buttons } from "../../layout/buttons/Buttons";
import { Cards } from "../../layout/cards/Cards";
import { getJishoItem, getReports } from "../../services/Reports";
import rightArrow from "../../assets/img/arrow-right.png";
import "./Reports.scss";
import { Thumbnails } from "../../layout/thumbnails/Thumbnails";
import { getCardType } from "../../helpers/cards/getCardType";
import { Audio } from 'react-loader-spinner'
import { createCard, updateCard } from "../../services/Cards";
import toast, { Toaster } from 'react-hot-toast';
import sendBtn from "../../assets/img/btn/valid.png";
import { createNotification } from "../../services/Notification";
import { DateTime } from "luxon";
const activeStyle = {
  padding: "5px 0px",
  backgroundColor: "#4EA1D3",
  width: "45%",
  color: "#FCFCFC",
};
const inactiveStyle = {
  padding: "5px 0px",
  backgroundColor: "transparent",
  color: "#FCFCFC",
  width: "45%",
  boxShadow: "0px 0px 0px 0px #d8e9ef",
};
export function Reports() {
  const [filter, setFilter] = useState({ type: 0, subType: 0 }); //type: 0 = "mauvaises traductions", 1 = "Cartes manquantes", subType: 0 = "Kanji", 1 = "Vocabulaire"
  const [reports, setReport]: [any, any] = useState([]);
  const [active, setActive] = useState(0);
  const [loading, setLoading] = useState(true);
  const [jishoLoading, setJishoLoading] = useState(false);
  const [activeJishoItem, setActiveJishoItem]: any = useState(null);
  const auth = useSelector((state: any) => state.auth);
  const notify = (msg: string) => toast.success(msg);
  useEffect(() => {
    (async () => {
      try {
        const queryFilter = { type: getType(filter.type) };
        const reportsQuery = await getReports(queryFilter, auth.token);
        if (reportsQuery.data.length > 0) {
          if (filter.type === 0) {

            setReport(
              reportsQuery.data.filter(
                (r: any) => getCardType(r.itemId) === filter.subType
              )
            );
          } else {
            let newCard = reportsQuery.data
            // if (getCardType(newCard) === 1) newCard.senses.map((s: any, i: number) => s.french_definitions = reports[active].itemId.senses[i].french_definitions)
            // else newCard.meanings[1] = reports[active].itemId.meanings[1]
            setReport(newCard);
          }
          setActive(0);
        } else {
          setReport([]);
          setActive(0);
        }
        setLoading(false);
      } catch (error) {
        console.log("error catched !", error);
      }
    })();
    return () => { };
  }, [filter]);
  const onDeleteThumb = async (_id: number) => {
    const deletedReportNote =  [...reports].filter((r: any, i: number) => r._id === _id)[0].note;
    if (filter.type === 1) {
      const txt = `FR: \nCible du signalement: ${deletedReportNote}\nVotre signalement a été traité et les cartes en rapport avec "${deletedReportNote}" ont été mises à jour. Merci pour votre aide !\n\nEN:\nTarget of the report: ${deletedReportNote}\nYour report has been treated and cards similar to "${deletedReportNote}" has been created. Thank you for your help !`
      const notifSent = await createNotification(auth.token, {type:0, to: reports[active].from._id, from: "62cf2c607ea48dc1ce14b0e3", creationDate: DateTime.local({zone:"utc"}).toMillis(), title: "✔️ Report/Signalement", description: txt, seenBy: [] } )
    }
    const newReports = [...reports].filter((r: any, i: number) => r._id !== _id);
    setReport(newReports);
    setActive(0);
  }
  const onClickThumbs = async (index: number) => {
    setJishoLoading(true);
    const jisho = await getJishoItem(
      filter.type === 0 ? filter.subType : reports[index].itemType === "Words" ? 1 : 0,
      filter.type === 0 ? filter.subType === 0 ? reports[index].itemId.kanji : reports[index].itemId.slug : reports[index].note,
      filter.type === 1,
      auth.token
    );

    if (jisho && !jisho.data.err) {
      let newCard = jisho.data.item
      console.log("newCard", newCard)
      //need refactoring
      if (filter.type === 0) {
        if (getCardType(newCard) === 1) newCard.senses.map((s: any, i: number) =>{
          if (reports[index].itemId.senses[i]?.french_definitions) {
            s.french_definitions = reports[index].itemId.senses[i]?.french_definitions
          } else {
            s.french_definitions = ["---"]
          }
          
          return s
        })
        else newCard.meanings[1] = reports[index].itemId.meanings[1]
      } else {
        newCard.map((nC: any) => {
          return nC.senses.map((s: any) => {
            s.french_definitions = [...s.english_definitions]
            return s
          })
        })

      }
      setActiveJishoItem(newCard);
      setJishoLoading(false)
    }
    setActive(index);
  };

  const onSend = async (item?: any) => {
    if (window.confirm("Êtes-vous sûr de cette correction ?")) {
      if (filter.type === 0) {
        //we need to add the _id of the item reported to the card
        const item: any = activeJishoItem
        item._id = reports[active].itemId._id
        const word = await updateCard(activeJishoItem, getCardType(activeJishoItem), auth.token)
        if (word) {
          console.log(reports[active])
          const txt = `FR: \nCible du signalement: ${getCardType(activeJishoItem) === 0 ? reports[active].itemId.kanji :reports[active].itemId.slug}\nVotre signalement a été traité et la carte a été mise à jour. Merci pour votre aide !\n\nEN:\nTarget of the report: ${getCardType(activeJishoItem) === 0 ? reports[active].itemId.kanji :reports[active].itemId.slug}\nYour report has been treated and the card has been updated. Thank you for your help !`
          const notifSent = await createNotification(auth.token, {type:0, to: reports[active].from._id, from: "62cf2c607ea48dc1ce14b0e3", creationDate: DateTime.local({zone:"utc"}).toMillis(), title: "✔️ Report/Signalement", description: txt, seenBy: [] } )
          const newReports = [...reports].filter((r: any) => r.itemId._id !== reports[active].itemId._id)
          setReport(newReports)
          setActive(0)
          setActiveJishoItem(null)
          notify("Correction envoyée !")
          
        }
      } else if (filter.type === 1) {
        //we need to create the card
        if (item) {
          console.log("item will be created", item)
          const createdCard = await createCard(item, getCardType(item), auth.token)
          if (createdCard) {
            const newJishoCardsList = [...activeJishoItem].filter((r: any) => r.slug !== item.slug)
            setActive(0)
            setActiveJishoItem(newJishoCardsList)
            notify("Carte créée !")
          }
        }

      }

    }
  }

  if (loading || active === -1) return <div>Chargement...</div>
  return (
    <div className="ctnReports">
      <Toaster />
      <h1>Signalements</h1>
      <div className="ctnMainCategoryBtn">
        <Buttons
          key={"btn1"}
          title="Mauvaise traduction"
          active={filter.type === 0}
          action={() => {
            setActive(-1);
            setActiveJishoItem(null)
            setFilter({ subType: 0, type: 0 });
            
          }}
        ></Buttons>
        <Buttons
          key={"btn2"}
          title="Carte manquante"
          active={filter.type === 1}
          action={() => {
            setActive(-1);
            setActiveJishoItem(null)
            setFilter({ subType: 0, type: 1 });
          }}
        ></Buttons>
      </div>
      <div key={"sscrrr"} className="ctnTable">
        {filter.type === 0 ?<div key={"subCtg"}  className="ctnSubCategoryBtn">
            <Buttons
              key={"subbtn2"}
              style={filter.subType === 0 ? activeStyle : inactiveStyle}
              title="Kanji"
              active={filter.subType === 0}
              action={() => setFilter({ ...filter, subType: 0 })}
            ></Buttons>
            <Buttons
              key={"subbtn1"}
              style={filter.subType === 1 ? activeStyle : inactiveStyle}
              title="Vocabulaire"
              active={filter.subType === 1}
              action={() => setFilter({ ...filter, subType: 1 })}
            ></Buttons>
          </div> : null}
          
        {reports.length > 0 && filter.type === 0 ? (
          <>
            {reports[active].note.length > 0 ? (
              <p className="reportNote">Note sur le signalement: {reports[active].note}</p>
            ) : null}
            <div key={"scr0"} className="ctnActiveReport">
              <div key={"card1"} className="card">
                <Cards isEditMode={false} card={reports[active].itemId}></Cards>
              </div>
              <img src={rightArrow} alt="flèche de droite" />
              {!jishoLoading && activeJishoItem !== null ? (
                <div key={"card2"} className="card">
                  <Cards isEditMode card={activeJishoItem}></Cards>
                </div>
              ) : null}
              {!jishoLoading && activeJishoItem !== null ? (
                <img style={{ width: 25, cursor: "pointer" }} src={sendBtn} onClick={() => onSend()} alt="Send report" />
              ) : null}
              {jishoLoading ? (
                <Audio
                  height="80"
                  width="80"
                  color="white"
                  ariaLabel="loading"
                />
              ) : null}
            </div>
            <div key={"scr1"} className="ctnReportsList scroll">
              {reports.map((r: any, thumbIndex: number) => {
                return (
                  <div key={"Thumbeditsec" + thumbIndex} className="reportCard">
                    <Thumbnails
                      onDeleteAction={onDeleteThumb}
                      type={"edit"}
                      key={"Thumbedit" + thumbIndex}
                      keyIndex={thumbIndex}
                      action={onClickThumbs}
                      
                      card={r.itemId}
                      reportId={r._id}
                    ></Thumbnails>
                  </div>
                );
              })}
            </div>
          </>
        ) : null}
        {reports.length > 0 && filter.type === 1 ? (
          <>
            <div className="ctnJishoCreate scroll">
              {!jishoLoading && activeJishoItem !== null && activeJishoItem.length > 0 ? (
                activeJishoItem.map((r: any, thumbIndex: number) => {
                  return (
                    <div key={"card" + thumbIndex} className="card">
                      <img style={{ width: 25, cursor: "pointer" }} src={sendBtn} onClick={() => onSend(r)} alt="Send report" />
                      <Cards isEditMode={true} card={r}></Cards>
                    </div>

                  )
                })
              ) : null}
              {jishoLoading ? (
                <Audio
                  height="80"
                  width="80"
                  color="white"
                  ariaLabel="loading"
                />
              ) : null}
            </div>


            <div key={"scr2"} className="ctnReportsList scroll">
              {reports.map((r: any, thumbIndex: number) => {
                return (
                  <div key={"thumbsect" + thumbIndex} className="reportCard">
                    <Thumbnails
                      key={"thumb" + thumbIndex}
                      keyIndex={thumbIndex}
                      type={"create"}
                      action={onClickThumbs}
                      card={r}
                      reportId={r._id}
                      onDeleteAction={onDeleteThumb}
                    ></Thumbnails>
                  </div>
                );
              })}
            </div>
          </>
        ) : null}
        {reports.length === 0 && <p>Aucun signalement</p>}
      </div>
    </div>
  );
}

function getType(id: number) {
  switch (id) {
    case 0:
      return "edit";
    case 1:
      return "create";
    default:
      return "edit";
  }
}
