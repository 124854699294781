export function getVocabularyMeanings(senses: any, lang: "english_definitions" | "french_definitions") {
    const meanings: Array<any> = []
    try {
        senses.forEach((sense: any, index:number) => {
            const subMeanings: String[] = []
            sense[lang].forEach((meaning: string) => {
                subMeanings.push(meaning)
            })
            meanings.push(subMeanings)
        })
        return meanings
    } catch (e) {
        return []
    }

}