import { API_URL } from './../env/env';
import axios from "axios";

export async function login(uid: string) {
    return axios.get(`${API_URL}/login/${uid}`)
}

export async function getUser(id:string, token:string) {
    return axios.get(`${API_URL}/user/${id}`, { headers: { Authorization: `Bearer ${token}` } })
}
