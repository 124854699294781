import "./Dashboard.scss";

export function Dashboard() {
	//addings cards with specific filter to a deck and then create this deck
	// const auth = useSelector((state: any) => state.auth);
	// useEffect(() => {
	//     (async () => {
	//       try {
	//         const DECK_TYPE = 2; // 1 = kanjis 2 = vocabularies
	//         const cards = await getCards({jlpt: {$in: [1]}}, 1, auth.token);
	//         console.log("cards", cards);
	//         if (cards.data.values.length > 0) {
	//             const cardsIds = cards.data.values.map((card: any) => {
	//                 return {_id:card._id, insertionDate: 1660332634965.0}
	//             });
	//             const deckToBeCreated = {
	//                 name: "Vocab JLPT N1",
	//                 description:"",
	//                 type: DECK_TYPE,
	//                 content: cardsIds,
	//                 author: "62cf2c607ea48dc1ce14b0e3",
	//                 originalAuthor: "62cf2c607ea48dc1ce14b0e3",
	//                 shared:true,
	//                 pinned:true,
	//                 creationDate:1667865580709.0,
	//             }
	//             console.log("deckToBeCreated", deckToBeCreated)
	//             const deckCreateReq = await createDeck(deckToBeCreated, auth.token);
	//         }
	//       } catch (error) {
	//         console.log("error catched !", error);
	//       }
	//     })();
	//     return () => { };
	//   }, [true]);

	//Copilot, I've got a mission for you ! Are you reade ?
	//Yes sir !
	//I need you to get all the vocabulary cards from the database, and then check for duplicates slug in the cards array.
	//After that, I need you to get the cards _id of the duplicates and then update all the decks using these cards to use the first card of the duplicates instead.

	// const auth = useSelector((state: any) => state.auth);
	// useEffect(() => {
	// 	(async () => {
	// 		try {
	// 			const cards = await getCards({}, 1, auth.token);
	// 			console.log("cards", cards);
	// 			if (cards.data.values.length > 0) {
	// 				const cardsWithoutDuplicates: Array<any> = [];
	// 				const arrayOfCountsDuplicatedSlugs: Array<any> = [];
	// 				cards.data.values.forEach((card: any) => {
	// 					if (cardsWithoutDuplicates.filter((cardWithoutDuplicates: any) => cardWithoutDuplicates.slug === card.slug).length === 0) {
	// 						cardsWithoutDuplicates.push(card);
	// 					} else {
	// 						console.log("duplicate", card.slug, card._id);
	// 						if (arrayOfCountsDuplicatedSlugs.filter((count: any) => count.slug === card.slug).length === 0) {
	// 							arrayOfCountsDuplicatedSlugs.push({ slug: card.slug, count: 1, ids: [card._id] });
	// 						} else {
	// 							const index = arrayOfCountsDuplicatedSlugs.findIndex((count: any) => count.slug === card.slug);
	// 							arrayOfCountsDuplicatedSlugs[index].count += 1;
	// 							arrayOfCountsDuplicatedSlugs[index].ids.push(card._id);
	// 						}
	// 					}
	// 				});
	// 				console.log("cardsWithoutDuplicates", arrayOfCountsDuplicatedSlugs);

	// 				cardsWithoutDuplicates.forEach((card: any) => {
	// 					const duplicatedCardsRelatedIndex = arrayOfCountsDuplicatedSlugs.findIndex((count: any) => count.slug === card.slug);
	// 					if (duplicatedCardsRelatedIndex !== -1) {
	// 						arrayOfCountsDuplicatedSlugs[duplicatedCardsRelatedIndex].replaceBy = { _id: card._id, slug: card.slug };
	// 					}
	// 				});
	// 				console.log("cardsWithoutDuplicates", arrayOfCountsDuplicatedSlugs);
	// 				const cardIdToBeReplaced: Array<any> = [];
	// 				arrayOfCountsDuplicatedSlugs.forEach((count: any) => {
	// 					if (count.count > 0) {
	// 						cardIdToBeReplaced.push(...count.ids);
	// 					}
	// 				});
	// 				console.log("cardIdToBeReplaced", cardIdToBeReplaced);
	// 				//we need to get the list of deck that contains the duplicates
	// 				const deckFilter = { type: 2, content: { $elemMatch: { _id: { $in: cardIdToBeReplaced } } } };
	// 				const decksWithDuplicates = await getDecks(auth.token, deckFilter);
	// 				console.log("decksWithDuplicates", decksWithDuplicates);

	// 				if (decksWithDuplicates.data.values.length > 0) {
	// 					const decks = [...decksWithDuplicates.data.values]
	// 					decks.forEach(async (deck: any) => {
	// 						const content = deck.content;
    //                         console.log("old content", content.length)
	// 						let newContent: any = []
    //                         content.forEach((card: any) => {
    //                             //TODO
    //                             if (cardIdToBeReplaced.includes(card._id)) {
    //                                 const index = arrayOfCountsDuplicatedSlugs.findIndex((count: any) => count.ids.includes(card._id));
    //                                 if (index !== -1) {
    //                                     const cardToReplace = {_id:arrayOfCountsDuplicatedSlugs[index].replaceBy._id, insertionDate:card.insertionDate};
    //                                     //check if the card is already in the deck
	// 									if (newContent.filter((c:any) => c._id === cardToReplace._id).length>0) {
	// 										console.log("card already in deck !")
	// 									} else {
	// 										newContent.push(cardToReplace);
	// 									}
    //                                 }
    //                             } else {
	// 								newContent.push(card);
	// 							}
	// 						});
							
	// 						newContent = newContent.map((nC:any) => {
	// 							return {_id:nC._id, insertionDate:nC.insertionDate}
	// 						})
	// 						console.log("newContent", newContent.length)
							
	// 							console.log("sending deck update request !")
	// 							const newDeck = { ...deck, content: newContent };
	// 							const deckUpdateReq = await updateDeck(newDeck, auth.token);
	// 							console.log("deckUpdateReq", deckUpdateReq);
							
	// 						console.log("deck", deck);
	// 					});
	// 				}

	// 				if (arrayOfCountsDuplicatedSlugs.length > 0) {
	// 					//we need to delete theses cards duplicated
	// 					const idsToDelete:string[]= []
	// 					arrayOfCountsDuplicatedSlugs.forEach((count:any) => {
	// 						if (count.count > 0) {
	// 							idsToDelete.push(...count.ids)
	// 						}
	// 					})
	// 					console.log("idsToDelete", idsToDelete)
	// 					const cardsDeleteReq = await deleteCards(idsToDelete, auth.token);
	// 					console.log("cardsDeleteReq", cardsDeleteReq)
	// 				} else {
	// 					console.log("no duplicates found in the database, SUCCESS !!")
	// 				}
	// 			}
	// 		} catch (error) {
	// 			console.log("error catched !", error);
	// 		}
	// 	})();
	// 	return () => {};
	// }, [true]);

	return (
		<div className="ctnDashboard">
			<h1>Dashboard</h1>
		</div>
	);
}
