import { getCardType } from "./getCardType";

const onChange = (e: any, card:any, type: number, mainIndex: number, subIndex: number | null = null, lang?: number) => {
    const newCard = {...card};
    switch (type) {
        case 0:
            //meanings en/fr
            if (getCardType(card) === 1 && subIndex === null) return
            if (e.target.innerText === "") {
                if (window.confirm("Are you sure you want to delete this meaning ?")) {
                    //@ts-ignore
                    if (getCardType(card) === 0) newCard.meanings[lang].splice(mainIndex, 1)
                    else newCard.senses[mainIndex][lang === 0 ? "english_definitions" : "french_definitions"].splice(subIndex, 1)
                    //we delete the sense if there is no french definition
                    const needToSplice = lang === 0 ? newCard.senses[mainIndex].french_definitions.length === 0 : newCard.senses[mainIndex].english_definitions.length === 0
                    if (getCardType(card) === 1 && needToSplice) {
                        newCard.senses.splice(mainIndex, 1)
                    }
                } else {
                    //@ts-ignore
                    e.target.innerText = getCardType(card) === 0 ? newCard.meanings[lang][mainIndex] : newCard.senses[mainIndex][lang === 0 ? "english_definitions" : "french_definitions"][subIndex]
                }
            } else {
                if (getCardType(card) === 0) {
                    //@ts-ignore
                    newCard.meanings[lang][mainIndex] = e.target.innerText
                } else {
                    //@ts-ignore
                    newCard.senses[mainIndex][lang === 0 ? "english_definitions" : "french_definitions"][subIndex] = e.target.innerText
                }
            }
            return newCard

        case 1:
            //kunyomi
            if (getCardType(card) === 0) {
                if (e.target.innerText === "") {
                    if (window.confirm("Are you sure you want to delete this kun reading ?")) {
                        newCard.kun_readings.splice(mainIndex, 1)

                    } else {
                        e.target.innerText = newCard.kun_readings[mainIndex]
                    }
                } else {
                    newCard.kun_readings[mainIndex] = e.target.innerText
                }
            } else {
                if (e.target.innerText === "") {
                    if (window.confirm("Are you sure you want to delete this kun reading ?")) {
                        if (newCard.japanese[mainIndex].word) {
                            delete newCard.japanese[mainIndex].reading
                        } else {
                            newCard.japanese.splice(mainIndex, 1)
                        }

                    } else {
                        e.target.innerText = newCard.japanese[mainIndex].reading
                    }
                } else {
                    newCard.japanese[mainIndex].reading = e.target.innerText
                }
            }
            return newCard
        case 2:
            //onyomi
            if (getCardType(card) === 0) {
                if (e.target.innerText === "") {
                    if (window.confirm("Are you sure you want to delete this kun reading ?")) {
                        newCard.on_readings.splice(mainIndex, 1)
                    } else {
                        e.target.innerText = newCard.on_readings[mainIndex]
                    }
                } else {
                    newCard.on_readings[mainIndex] = e.target.innerText
                }
            } else {
                if (e.target.innerText === "") {
                    if (window.confirm("Are you sure you want to delete this kun reading ?")) {
                        if (newCard.japanese[mainIndex].reading) {
                            delete newCard.japanese[mainIndex].word
                        } else {
                            newCard.japanese.splice(mainIndex, 1)
                        }
                    } else {
                        e.target.innerText = newCard.japanese[mainIndex].word
                    }
                } else {
                    newCard.japanese[mainIndex].word = e.target.innerText
                }
            }
            return newCard
            
    }


}
export default onChange