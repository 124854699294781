
import { createSlice } from '@reduxjs/toolkit'
interface IState {
  token: any
  _id: any
}
export const userSlice = createSlice({
  name: 'user',
  initialState: {
    auth: {
      token: null,
      _id: null
    },
    user: {
      _id: null,
    }
    
  },
  reducers: {
    set: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.auth = action.payload.auth
      state.user = action.payload.user
    },
  },
})

// Action creators are generated for each case reducer function
export const { set } = userSlice.actions

export default userSlice.reducer