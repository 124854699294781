import {Route, Routes,} from "react-router-dom";
import { Announcements } from "../../pages/announcements/Announcements";
import { Contact } from "../../pages/contact/Contact";
import { Dashboard } from "../../pages/dashboard/Dashboard";
import { Login } from "../../pages/login/Login";
import { Reports } from "../../pages/reports/Reports";
export function Navigation() {
    return (
        <Routes>
            <Route path="*" element={<Reports />}></Route>
            <Route path="/dashboard" element={<Dashboard />}></Route>
            <Route path="/reports" element={<Reports />}></Route>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/contact" element={<Contact />}></Route>
            <Route path="/announcements" element={<Announcements />}></Route>
        </Routes>
    )
}