import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getNotifications } from "../../../services/Notification";
import "./List.scss";
interface IAnnouncement {
	_id: string;
	type: number;
	title: string;
	description: string;
	creationDate: number;
	seenBy: string[];
	from: string;
	to: string;
	_v?: number;
}
export function AnnouncementsList() {
	const [announcements, setAnnouncements] = useState<IAnnouncement[]>([]);
	//auth token
	const { token } = useSelector((state: any) => state.auth);
	useEffect(() => {
		(async () => {
			const announcements = await getNotifications(token, { type: 1 });
			if (announcements.data.length > 0) setAnnouncements(announcements.data);
		})();

		return () => {};
	}, []);
	return (
		<section className="ctnAnnouncements">
			{announcements.map((announcement, index) => {
				return (
					<div key={"contactMsg" + index} className="ctnMsgRow">
						<div className="ctnTitleActions">
							<h3>{announcement.title}</h3>
						</div>
						<p dangerouslySetInnerHTML={{
								__html: announcement.description,
							}}></p>
						<p className="minorInfos">le : {DateTime.fromMillis(announcement.creationDate).toLocaleString(DateTime.DATETIME_MED)}</p>
					</div>
				);
			})}
		</section>
	);
}
