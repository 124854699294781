import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyDr9ceUUNaBK8cyZrlNY1PbCH8gpTsDzK8",
    authDomain: "oboemasho-8edc5.firebaseapp.com",
    projectId: "oboemasho-8edc5",
    storageBucket: "oboemasho-8edc5.appspot.com",
    messagingSenderId: "48131854828",
    appId: "1:48131854828:web:bc9b36af970300afb570c9",
    measurementId: "G-LW9YH04485"
};

export const app = initializeApp(firebaseConfig);