import { useEffect, useState } from "react"
import { Buttons } from "../../layout/buttons/Buttons"
import "./Login.scss"
import { firebaseLogin } from "../../services/Firebase"
import { login } from "../../services/User"
import { useDispatch } from 'react-redux'
import { set } from "../../stores/user/userSlice"
import { useNavigate } from "react-router-dom"
import { DateTime } from "luxon"
interface Form {
    email: string,
    password: string
}

export function Login() {
    const [form, setForm] = useState({ email: "", password: "" })
    const [formError, setError] = useState("")
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const updateForm = (value: string, attr: "email" | "password") => {
        setForm({ ...form, [attr]: value })
    }
    const submit = async () => {
        try {
            const userLogged: any = await firebaseLogin(form.email, form.password)
            const uid = userLogged.user.uid
            const user:any = await login(uid)
            if (user && user?.data.role === 1) {
                dispatch(set({auth:{token: user.data.token, _id: user.data._id, expiresDate: DateTime.now().plus({day:7}).toMillis() }, user: user.data}))
                navigate("/dashboard")
            } else {
                throw {code: "Vous ne devriez pas être là!"}
            }
        } catch (error: any) {
            setError(error.code)
            return
        }
    }

    return (
        <div className="ctnLoginPage">
            <h1>Login page!</h1>
            <form>
                <div>
                    <label>Email:</label>
                    <input onChange={(e: any) => updateForm(e.nativeEvent.target.value, "email")} type="email" name="email" />
                </div>
                <div>
                    <label>Password:</label>
                    <input onChange={(e: any) => updateForm(e.nativeEvent.target.value, "password")} type="password" name="password" />
                </div>
                {/* @ts-ignore */}
                {formError ? <p className="error">{formError}</p> : null}
                <Buttons title="Connexion" disabled={!isLoginFormValid(form)} action={() => submit()}></Buttons>
            </form>
        </div>

    )
}

function isLoginFormValid(form: Form) {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(form.email) && form.password.length > 5
}