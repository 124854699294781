import { useSelector } from "react-redux";
import "./TopNav.scss";


export function TopNav() {
    const user = useSelector((state: any) => state.user)
    return (
        <div className="ctnTopNav">
            <div className="loggedInAsCtn">
                <span>Connecté en tant que : {user.username}</span>
            </div>
        </div>
    )
}