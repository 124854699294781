import { getVocabularyMeanings } from "../../helpers/cards/getVocabularyMeanings"
import { getVocabularyReadings } from "../../helpers/cards/getVocabularyReadings"

export class CardsFactory {
    card: any
    type: number
    constructor(card: any, type: number) {
        this.card = card
        this.type = type
    }

    get title() {
        const titles = [this.card?.kanji, this.card?.slug]
        return titles[this.type]
    }

    get meanings() {
        switch (this.type) {
            case 0:
                return { en: this.card?.meanings[0], fr: this.card?.meanings[1] }
            case 1:
                return { en: getVocabularyMeanings(this.card?.senses, "english_definitions"), fr: getVocabularyMeanings(this.card?.senses, "french_definitions") }
            default:
                return { en: undefined, fr: undefined }
        }
    }

    get readings():any {
        switch (this.type) {
            case 0:
                return { kun: this.card.kun_readings, on: this.card.on_readings }
            case 1:
                return getVocabularyReadings(this.card)
            default:
                return { kun: undefined, on: undefined }
        }
    }

}