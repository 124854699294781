import { getCardType } from "./getCardType"
/**
 * 
 * @param e 
 * @param card 
 * @param type 0 = senses/meanings 2 kunyomi, 1 onyomi
 */
const onAttrAdd = (e:any, card:any, type:number, lang?:number, index?:number) => {
    let newCard = {...card}
    const cardType = getCardType(card)
    switch (type) {
        case 0:
            //Adding kanji meanings
            if (cardType === 0) {
                //@ts-ignore
                newCard.meanings[lang].push("----")
            } else {
                //@ts-ignore
                if (index !== undefined) {
                    
                    newCard.senses[index][lang === 0 ? "english_definitions" : "french_definitions"].push("----")
                } else {
                    //We need to add a new group of sense 
                    const newSenseGroup = {
                        english_definitions:["----"],
                        french_definitions:["----"]
                    }
                    newCard.senses.push(newSenseGroup)
                }
                
            }

            return newCard
        case 2:
            //Adding kunyomi
            if (cardType === 0) {
                newCard.kun_readings.push("----")
            } else {
                newCard.japanese.push({word:"----"})
            }
            return newCard
        case 1:
            //Adding onyomi
            if (cardType === 0) {
                newCard.on_readings.push("----")
            } else {
                newCard.japanese.push({reading:"----"})
            }
            return newCard
    }

}

export default onAttrAdd