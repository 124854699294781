import "./VerticalNav.scss";
import logo from "../../../assets/img/logo.png"
import {
    Link, useLocation
  } from "react-router-dom";
export function VerticalNav() {
    const activePage = useLocation()
    return (
        <nav>
            <ul>
                <li><Link to={"/dashboard"}><img src={logo} alt="Logo Oboemasho" /></Link></li>
                <li><Link to="/dashboard" className={activePage.pathname === "/dashboard" ? "active" : ""}>Dashboard</Link></li>
                <li><Link className={activePage.pathname === "/reports" ? "active" : undefined} to={"/reports"}>Signalements</Link></li>
                <li><Link className={activePage.pathname === "/contact" ? "active" : undefined} to={"/contact"}>Contact</Link></li>
                <li><Link className={activePage.pathname === "/announcements" ? "active" : undefined} to={"/announcements"}>Annonces</Link></li>
            </ul>
        </nav>
    )
}