import "./Buttons.scss";
export function Buttons({title, action, disabled = false, active = false, style = undefined}: {title: string, action: () => void, disabled?: boolean, active?: boolean, style?: React.CSSProperties | undefined}) {
    return (
            <button type="button" onClick={() => action()} style={style} className={getBtnStyle(disabled, active)}>{title}</button>
    )
}

function getBtnStyle(disabled:boolean, active:boolean) {
    if (disabled) {
        return "buttons disabled"
    } else if (active) {
        return "buttons activeBtn"
    } else {
        return "buttons"
    }
} 