import { API_URL } from './../env/env';
import axios from "axios";

export function getReports(filters:any, token: string) {
    return axios.get(`${API_URL}/reports/${JSON.stringify(filters)}`, { headers: { Authorization: `Bearer ${token}` } })
}

export function getJishoItem(type:any, item:string, searchMode:boolean,token:string) {
    console.log(`${API_URL}/reports/jisho/${type}/${item}/${searchMode}`)
    return axios.get(`${API_URL}/reports/jisho/${type}/${item}/${searchMode}`, { headers: { Authorization: `Bearer ${token}` } })
}

export function deleteReport(_id:string, token:string) {
    return axios.delete(`${API_URL}/reports/${_id}`, { headers: { Authorization: `Bearer ${token}` } })
}