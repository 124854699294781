export function getVocabularyReadings(card:any) {
    const readings: String[] = []
    const alternatives: String[] = []
    try {
        card.japanese.forEach((jp: any) => {
            if (jp.word) {
                alternatives.push(jp.word)
            }
            if (jp?.reading) {
                readings.push(jp.reading)
            }
        })
        return {readings, alternatives}
    } catch(e) {
        return {readings: undefined, alternatives: undefined}
    }
}