import axios from "axios"
import { API_URL } from "../env/env"

export const getAllContact = (token:string) => {
    return axios.post(`${API_URL}/contact/get`, {filter: {archived:false}},{ headers: { Authorization: `Bearer ${token}` } }  )
}

export const updateContact = (contact: any, token: string) => {
    return axios.post(`${API_URL}/contact/update`, {contact}, { headers: { Authorization: `Bearer ${token}` } });
}
