import { CardsFactory } from "../../factories/cards/CardsFactory";
import { getCardType } from "../../helpers/cards/getCardType";
import "./Thumbnails.scss";
import enFlag from "../../assets/img/flags/en.png";
import frFlag from "../../assets/img/flags/fr.png";
import deleteBtn from "../../assets/img/btn/delete.png";
import { deleteReport } from "../../services/Reports";
import { useSelector } from "react-redux";
export function Thumbnails({ card, action, keyIndex, type, reportId, onDeleteAction }: { card: any, action: Function, keyIndex: any, type: "edit" | "create", reportId: string, onDeleteAction: Function }) {
    let Card: any
    const auth = useSelector((state: any) => state.auth);
    if (type === "edit") Card = new CardsFactory(card, getCardType(card))
    const onDelete = async (e:any) => {
        e.stopPropagation()
        const reportDeleted = await deleteReport(reportId, auth.token)
        if (reportDeleted) {
            onDeleteAction(reportId)
        }
    }
    return (
        <div key={keyIndex + "thumb" + type} onClick={() => { action(keyIndex) }} className="ctnThumbnail">
            <img className="deleteBtn" onClick={(e) => onDelete(e)} src={deleteBtn} alt="Delete" />
            {type === "edit" && Card.title ? (
                <>
                
                    <h2>{Card.title}</h2>
                    <div className="ctnThumbMeanings">
                        <img src={enFlag} alt="Drapeau anglais" />
                        <p>{Card.meanings.en[0]}</p>
                    </div>
                    <div className="ctnThumbMeanings">
                        <img src={frFlag} alt="Drapeau français" />
                        <p>{Card.meanings.fr[0]}</p>
                    </div>
                </>

            ) : null}
            {type === "create" && card ? (
                <div className="ctnDefMeanings">
                    {/* <img className="deleteBtn" onClick={(e) => onDelete(e)} src={deleteBtn} alt="Delete" /> */}
                    <h2>{card.note}</h2>
                </div>
            ) : null}
        </div>
    )
}