import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { getAllContact, updateContact } from "../../services/Contact";
import "./Contact.scss";
import { DateTime } from "luxon";
import { RiMailSendLine } from "react-icons/ri";
import { TbArchive, TbSend } from "react-icons/tb";
import { createNotification } from "../../services/Notification";
import { store } from "../../stores/store";
import { Editor } from "@tinymce/tinymce-react";
import { toast, Toaster } from "react-hot-toast";

interface IUserRead {
	_id: string;
	email: string;
	username: string;
	role?: number;
	firebaseUid?: string;
	level: number;
	experience: number;
	stats: any;
	mentor: number;
}
interface IMessagesRead {
	_id: string;
	title: string;
	description: string;
	from: IUserRead;
	creationDate: number;
}
export const Contact = () => {
	const { token } = useSelector((state: any) => state.auth);
	const [messages, setMessages] = useState<any>([]);
	const [visibleInputIndex, setVisibleInputIndex] = useState<number>(-1);
	const notify = (msg: string) => toast.success(msg);
	let answer: string = "";
	useEffect(() => {
		(async () => {
			const messages: any = await getAllContact(token);
			if (messages.data.length > 0) setMessages(messages.data);

			console.log(messages);
		})();
		return () => {};
	}, []);

	const sendAnswer = async (id: string) => {
		if (answer.length < 3) return;
		const notification = {
			title: "RE: " + messages[visibleInputIndex].title,
			description: answer,
			to: messages[visibleInputIndex].from._id,
			from: store.getState().user._id,
			creationDate: DateTime.now().toMillis(),
			linkToContactId: messages[visibleInputIndex]._id,
			type: 0,
			seenBy: [],
		};
		const notificationSent = await createNotification(token, notification);
		if (notificationSent.data) {
			notify("Réponse envoyée");
			setVisibleInputIndex(-1);
		}
	};
	const archiveContact = async (index: any) => {
		let archivedContact = messages[index];
		archivedContact.archived = true;
		const contactUpdated = await updateContact(archivedContact, token);
		if (contactUpdated.status === 200) {
			messages.splice(index, 1);
			setMessages([...messages]);
			notify("Message archivé");
		}
	};
	return (
		<section className="ctnContactPage">
			<Toaster />
			<h2>Liste des messages</h2>
			<div className="ctnTable scroll">
				{messages.map((m: any, i: number) => {
					return (
						<div key={"contactMsg" + i} className="ctnMsgRow">
							<div className="ctnTitleActions">
								<h3>{m.title}</h3>
								<div className="actions">
									<RiMailSendLine onClick={() => setVisibleInputIndex(i)} title="Répondre" color="white" size={20}></RiMailSendLine>
									<TbArchive title="Archiver" onClick={() => (window.confirm("Souhaitez-vous archiver le message ?") ? archiveContact(i) : null)} color="#E85A71" size={20} />
								</div>
							</div>

							<p>{m.description}</p>
							<p className="minorInfos">
								De : {m.from.username}, le : {DateTime.fromMillis(m.creationDate).toLocaleString(DateTime.DATETIME_MED)}
							</p>
							<div style={visibleInputIndex !== i ? undefined : { display: "flex" }} className="ctnAnswerInputs">
								<Editor
									apiKey="hbw7as726a9y6se79pgk83ywn1uh9h5arfsnw510nwxw7xl4"
									initialValue="<p>This is the initial content of the editor.</p>"
									onEditorChange={(newValue, editor) => {
										answer = editor.getContent({ format: "html" });
									}}
									init={{
										height: 200,
										width: "100%",
										menubar: true,
										plugins: ["advlist", "autolink", "lists", "link", "image", "charmap", "preview", "anchor", "searchreplace", "visualblocks", "code", "fullscreen", "insertdatetime", "media", "table", "code", "help", "wordcount"],
										toolbar: "undo redo | blocks | " + "bold italic forecolor | alignleft aligncenter " + "alignright alignjustify | bullist numlist outdent indent | " + "removeformat | help",
										content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
									}}
								/>
								<TbSend onClick={() => sendAnswer(m._id)} className="sendBtn" size={20} color="white" />
							</div>
						</div>
					);
				})}
			</div>
		</section>
	);
};
