import { API_URL } from './../env/env';
import axios from 'axios';
export function getCards(filter: any, type: number, token:string) {
    const itemName: string = type === 0 ? 'kanjis' : 'words';
    return axios.post(`${API_URL}/${itemName}/get`, {filters:JSON.stringify(filter), pagination: JSON.stringify({page:1, perPage:10000})},{ headers: { Authorization: `Bearer ${token}` } });
}

export function updateCard(card: any, type: number, token: string) {
    const itemName: string = type === 0 ? 'kanjis' : 'words';
    return axios.put(`${API_URL}/${itemName}/update`, card, { headers: { Authorization: `Bearer ${token}` } });
}

export function createCard(card: any, type: number, token: string) {
    const itemName: string = type === 0 ? 'kanjis' : 'words';
    return axios.post(`${API_URL}/${itemName}/create`, card, { headers: { Authorization: `Bearer ${token}` } });
}

// export function deleteCards(cards:string[], token:string) {
//     return axios.post(`${API_URL}/words/delete`, {ids:cards}, { headers: { Authorization: `Bearer ${token}` } });
// }
